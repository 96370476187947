import React, { useState, useEffect } from 'react';
import axios from '../utilities/httpClient';
import Form from '../components/Form';
import History from '../components/History';
import Modal from '../components/Modal';
import Copyright from '../components/Copyright';
import ChatBotImage from '../components/ChatBotImage';
import { FiKey } from 'react-icons/fi';
import { defaultHistoricalSearches } from '../utilities/placeholders';
import './ChatbotPage.css';

function Chatbot() {
  const [topic, setTopic] = useState('demo');
  const [aasbSearch, setAasbSearch] = useState(true);
  const [closeModal, setCloseModal] = useState(false);
  const [clearing, setClearing] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [disableScroll, setDisableScroll] = useState(false);
  const [historyItems, setHistoryItems] = useState(defaultHistoricalSearches);

  const handleClearData = async () => {
    setClearing(true);
    const api_root = process.env.REACT_APP_BACKEND_API_ROOT;
    const api_endpoint = `${api_root}/pdfdb/clear`;
    try {
      const res = await axios.post(
        api_endpoint,
        {},
        {
          headers: {
            Authorization: apiKey,
          },
        }
      );
      console.log(res.data['message']);
    } catch (err) {
      console.log(err.response.data.error);
    }
    setClearing(false);
  };

  /* 💥 Fetch saved historical Q&As from local storage */
  useEffect(() => {
    // history from DB
    const activeUserFromLocalStrorage = JSON.parse(
      localStorage.getItem('activeUser')
    );
    if (activeUserFromLocalStrorage) {
      setHistoryItems(() => {
        if (activeUserFromLocalStrorage.searches[0]?.id === 1) {
          return [...activeUserFromLocalStrorage.searches];
        } else {
          return [
            ...defaultHistoricalSearches,
            ...activeUserFromLocalStrorage.searches.reverse(),
          ];
        }
      });
      return;
    }
    // history from local storage
    const historyItemsFromLocalStrorage = JSON.parse(
      localStorage.getItem('researchQuestions')
    );
    if (historyItemsFromLocalStrorage) {
      setHistoryItems(historyItemsFromLocalStrorage);
    }
  }, []);

  useEffect(() => {
    const activeUserFromLocalStrorage = JSON.parse(
      localStorage.getItem('activeUser')
    );
    if (activeUserFromLocalStrorage) {
      setApiKey(activeUserFromLocalStrorage.token);
    }
  }, []);

  const handleDisableScroll = (val) => {
    setDisableScroll(val);
  };

  const handleHistoryItems = (newItem) => {
    setHistoryItems((pre) => {
      const updatedItems = [
        pre[0],
        {
          id: newItem.id,
          question: newItem.question,
          answer: newItem.answer,
        },
        ...pre.slice(1),
      ];
      const activeUserFromLocalStrorage = JSON.parse(
        localStorage.getItem('activeUser')
      );
      if (activeUserFromLocalStrorage) {
        activeUserFromLocalStrorage.searches = updatedItems;
        localStorage.setItem(
          'activeUser',
          JSON.stringify(activeUserFromLocalStrorage)
        );
      } else {
        localStorage.setItem('researchQuestions', JSON.stringify(updatedItems));
      }
      return updatedItems;
    });
  };

  const handleDeleteHistoryItems = (deletedId) => {
    const updatedItems = historyItems.filter((item) => {
      return deletedId !== item.id;
    });
    setHistoryItems([...updatedItems]);
    const activeUserFromLocalStrorage = JSON.parse(
      localStorage.getItem('activeUser')
    );
    if (activeUserFromLocalStrorage) {
      activeUserFromLocalStrorage.searches = updatedItems;
      localStorage.setItem(
        'activeUser',
        JSON.stringify(activeUserFromLocalStrorage)
      );
    } else {
      localStorage.setItem('researchQuestions', JSON.stringify(updatedItems));
    }
  };

  const handleTopic = (value) => {
    setTopic(value);
  };

  const handleCloseModal = () => {
    setCloseModal(true);
  };

  return (
    <section className="react-page chat-bot-page">
      <History
        historyItems={historyItems}
        handleDeleteHistoryItems={handleDeleteHistoryItems}
        changeDisableScroll={handleDisableScroll}
      />
      <Form
        apiKey={apiKey}
        addNewSearch={handleHistoryItems}
        disableScroll={disableScroll}
        clearData={clearing}
        aasbSearch={aasbSearch}
        topic={topic}
        handleTopic={handleTopic}
      />
      <ChatBotImage />
      <Copyright clearData={handleClearData} apiKey={apiKey} />
      <Modal
        handleApiKey={setApiKey}
        handleCloseModal={handleCloseModal}
        closeModal={closeModal}
        aasbSearch={aasbSearch}
        setAasbSearch={setAasbSearch}
        handleTopic={handleTopic}
      />
      <FiKey
        className={`key-icon ${closeModal ? 'key-rotate' : ''}`}
        style={{ opacity: apiKey ? 1 : 0.6 }}
        onClick={() => {
          setCloseModal(false);
        }}
      />
    </section>
  );
}

export default Chatbot;
