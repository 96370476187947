import React, { useState } from 'react';
import './Copyright.css';
import icon from '../images/icon.png';

function Copyright({ apiKey, clearData }) {
  const [clearing, setClearing] = useState(false);
  const adminToken = process.env.REACT_APP_ADMIN_TOKEN;

  const handleClearData = async () => {
    if (apiKey === adminToken) {
      setClearing(true);
      await clearData();
      setClearing(false);
    } else {
      window.open('https://github.com/qinfang0623');
      window.open('https://github.com/camerontruong');
    }
  };

  return (
    <div className="copyright">
      <div>
        <img
          onClick={handleClearData}
          src={icon}
          alt="logo-icon"
          className={clearing ? 'spinner-grow' : ''}
          style={{ width: '25px', height: '25px' }}
          role="status"
        />
      </div>
      <div>
        COPYRIGHT © {new Date().getFullYear()}
        <br />
        CAMERON TRUONG & QINFANG LI.
      </div>
    </div>
  );
}

export default Copyright;
