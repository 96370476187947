import React, { useState, useEffect, useRef } from 'react';
import { BiCopyAlt, BiCheckCircle, BiLoader, BiTrash } from 'react-icons/bi';
import axios from '../utilities/httpClient';
import './History.css';

function History({
  historyItems,
  changeDisableScroll,
  handleDeleteHistoryItems,
}) {
  const [show, setShow] = useState(false);
  const [copied, setCopied] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  // 💥 Document-Wide Click Handler
  const divEl = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (!divEl.current) {
        return;
      }
      if (!divEl.current.contains(event.target)) {
        setShow(false);
        changeDisableScroll(false);
      }
    };
    document.addEventListener('click', handler, true);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  const handleCopy = (value) => {
    setCopied(true);
    navigator.clipboard.writeText(value);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  useEffect(() => {
    const activeUserFromLocalStrorage = JSON.parse(
      localStorage.getItem('activeUser')
    );
    if (activeUserFromLocalStrorage) {
      setLoggedIn(true);
    }
  }, []);

  const handleDeleteSearch = async (searchId) => {
    setDeleting(true);
    if (loggedIn) {
      const api_root = process.env.REACT_APP_BACKEND_API_ROOT;
      const api_path = 'search';
      const api_categ = `${searchId}`;
      const api_endpoint = `${api_root}/${api_path}/${api_categ}`;
      await axios.post(api_endpoint);
    }
    handleDeleteHistoryItems(searchId);
    setDeleting(false);
  };

  const renderedItems = historyItems.map((item, idx) => {
    return (
      <div key={item.id} className="accordion-item">
        <h2 className="accordion-header" id={`heading_${item.id}`}>
          <button
            className={
              historyItems.length === 1 || idx === 1
                ? 'accordion-button'
                : 'accordion-button collapsed'
            }
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse_${item.id}`}
            aria-expanded={item.id === historyItems.length ? 'true' : 'false'}
            aria-controls={`collapse_${item.id}`}
          >
            {item.question}
          </button>
        </h2>
        <div
          id={`collapse_${item.id}`}
          className={
            historyItems.length === 1 || idx === 1
              ? 'accordion-collapse collapse show'
              : 'accordion-collapse collapse'
          }
          aria-labelledby={`heading_${item.id}`}
          data-bs-parent="#accordionHistory"
        >
          <div className="accordion-body">
            <span
              style={{ display: item.id === 1 && 'none' }}
              className="copy-text"
              onClick={() => {
                handleCopy(`${item.question}: ${item.answer}`);
              }}
            >
              {copied ? <BiCheckCircle /> : <BiCopyAlt />}
            </span>
            <span
              style={{ display: item.id === 1 && 'none' }}
              className="delete-text"
              onClick={() => handleDeleteSearch(item.id)}
            >
              {deleting ? <BiLoader /> : <BiTrash />}
            </span>
            {item.answer}
          </div>
        </div>
      </div>
    );
  });

  return (
    <section className="history-comp">
      <button
        className="btn btn-light btn-show-up"
        type="button"
        onClick={() => {
          setShow(true);
          changeDisableScroll(true);
        }}
      >
        History
      </button>
      <div
        ref={divEl}
        className={show ? 'accordion show-up' : 'accordion'}
        id="accordionHistory"
      >
        {renderedItems}
      </div>
    </section>
  );
}

export default History;
