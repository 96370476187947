import React from 'react';

function SwitchBtn({ aasbSearch, setAasbSearch, handleTopic }) {
  return (
    <section>
      <fieldset className="form-group">
        <div
          className="form-check form-switch"
          style={{
            width: '140px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <input
            onChange={() => {
              setAasbSearch((pre) => !pre);
              if (aasbSearch) {
                handleTopic('acc');
              } else {
                handleTopic('demo');
              }
            }}
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckChecked"
            checked={aasbSearch && true}
            style={{ cursor: 'pointer' }}
          />
          <label className="form-check-label">AASB Search</label>
        </div>
      </fieldset>
    </section>
  );
}

export default SwitchBtn;
