import React from 'react';
import ContactForm from '../components/ContactForm';
import Copyright from '../components/Copyright';
import { FiKey } from 'react-icons/fi';
import './ContactPage.css';

function ContactPage({ user }) {
  return (
    <div className="contact-page">
      <ContactForm user={user} />
      <Copyright />
      <FiKey className="key-icon" style={{ opacity: 0.6, cursor: 'default' }} />
    </div>
  );
}

export default ContactPage;
