import React, { useState, useRef, useEffect } from 'react';
import axios from '../utilities/httpClient';
import './ButtonGroup.css';

function ButtonGroup({
  apiKey,
  topic,
  handleTopic,
  handleEmbed,
  handleLoading,
  fileReset,
}) {
  const inputEl = useRef(null);
  const [option, setOption] = useState('acc');
  const [file, setFile] = useState(null);
  const [embeddings, setEmbeddings] = useState(null);
  const [uploadSucceed, setUploadSucceed] = useState(false);
  const api_root = process.env.REACT_APP_BACKEND_API_ROOT;

  useEffect(() => {
    if (fileReset) {
      inputEl.current.value = '';
      setFile(null);
    }
  }, [fileReset]);

  const uploadFileToServer = async () => {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    const api_endpoint = `${api_root}/chatbot/upload`;
    handleLoading(true);
    try {
      const res = await axios.post(api_endpoint, formData, {
        headers: {
          Authorization: apiKey,
        },
      });
      setUploadSucceed(true);
      handleLoading(false);
      return {
        collectionName: res.data.collectionName,
        statusCode: res.status,
      };
    } catch (err) {
      handleLoading(false);
      return {
        collectionName: err.response.data,
        statusCode: err.response.request.status,
      };
    }
  };

  const handleChangeTopic = async (event) => {
    let clickedBtn = event.target.value;
    setOption(clickedBtn);
    if (clickedBtn === 'sum') {
      inputEl.current.click();
    }
    if (clickedBtn === 'upload' && file) {
      inputEl.current.value = '';
      const { collectionName, statusCode } = await uploadFileToServer();
      if (statusCode === 200) {
        let fName = file.name;
        if (fName.length > 50) {
          fName = fName.split('.')[0];
          fName = fName.replace(/_/g, ' ');
          const n1 = fName.slice(0, 20);
          const n2 = fName.slice(fName.length - 20);
          fName = `${n1} ... ${n2}.pdf`;
        }
        handleEmbed({ fileName: fName, collectName: collectionName });
        setEmbeddings(collectionName);
        handleTopic('sum');
        setFile(null);
      } else {
        handleEmbed(null);
        setFile(null);
      }
    }
  };

  // Remove embeddings file
  const handleRemoveFile = async () => {
    handleLoading(true);
    const api_endpoint = `${api_root}/embeddings/${embeddings}`;
    try {
      const res = await axios.post(api_endpoint);
      console.log(res.data['message']);
    } catch (err) {
      console.log(err.response.data.error);
    }
    handleEmbed(null);
    setEmbeddings(null);
    setUploadSucceed(false);
    handleLoading(false);
    handleTopic('sum');
  };

  const handleSetTopic = (event) => {
    event.preventDefault();
    handleTopic(option);
  };

  return (
    <form
      onSubmit={handleSetTopic}
      className="btn-group-vertical"
      role="group"
      aria-label="Vertical button group"
    >
      <input
        ref={inputEl}
        type="file"
        accept=".pdf"
        onChange={(event) => {
          let fName = event.target.files[0].name;
          if (fName.length > 50) {
            fName = fName.split('.')[0];
            fName = fName.replace(/_/g, ' ');
            const n1 = fName.slice(0, 20);
            const n2 = fName.slice(fName.length - 20);
            fName = `${n1} ... ${n2}.pdf`;
          }
          setFile(event.target.files[0]);
          handleEmbed({
            fileName: fName,
            collectName: null,
          });
        }}
        style={{ display: 'none' }}
      />
      <button
        onClick={handleChangeTopic}
        type="submit"
        className={topic === 'acc' ? 'btn btn-success' : 'btn btn-light'}
        value="acc"
        disabled={(uploadSucceed || !apiKey) && true}
        style={{ opacity: uploadSucceed || !apiKey ? '0.7' : 1 }}
      >
        Accounting
      </button>
      <button
        onClick={handleChangeTopic}
        type="submit"
        className={topic === 'fin' ? 'btn btn-success' : 'btn btn-light'}
        value="fin"
        disabled={(uploadSucceed || !apiKey) && true}
        style={{ opacity: uploadSucceed || !apiKey ? '0.7' : 1 }}
      >
        Finance
      </button>
      <button
        onClick={handleChangeTopic}
        type="submit"
        className={topic === 'eco' ? 'btn btn-success' : 'btn btn-light'}
        value="eco"
        disabled={(uploadSucceed || !apiKey) && true}
        style={{ opacity: uploadSucceed || !apiKey ? '0.7' : 1 }}
      >
        Economics
      </button>
      <button
        disabled={!apiKey && true}
        onClick={handleChangeTopic}
        type="submit"
        className={
          topic === 'sum' || topic === 'upload'
            ? 'btn btn-success'
            : 'btn btn-light'
        }
        value="sum"
        style={{
          display: (file || uploadSucceed) && 'none',
          borderBottomRightRadius: '0.375rem',
          borderBottomLeftRadius: '0.375rem',
        }}
      >
        Summarizer
      </button>
      <button
        disabled={!apiKey && true}
        onClick={handleChangeTopic}
        type="submit"
        className="btn btn-warning"
        value="upload"
        style={{
          display: (!file || uploadSucceed) && 'none',
          borderBottomRightRadius: '0.375rem',
          borderBottomLeftRadius: '0.375rem',
        }}
      >
        Upload
      </button>
      <button
        disabled={!apiKey && true}
        onClick={handleRemoveFile}
        type="submit"
        className="btn btn-danger"
        value="remove"
        style={{ display: !uploadSucceed && 'none' }}
      >
        Remove
      </button>
    </form>
  );
}

export default ButtonGroup;
