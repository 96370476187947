import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavBar.css';

const NavBar = ({ user, handleLogout }) => {
  return (
    <nav>
      <ul>
        <li>
          <NavLink to="/">ChatBot</NavLink>
        </li>
        <li>
          <NavLink to="/contact">Contact</NavLink>
        </li>
        <li style={{ display: user.token && 'none' }}>
          <NavLink to="/login">Login</NavLink>
        </li>
        <li onClick={handleLogout} style={{ display: !user.token && 'none' }}>
          <NavLink to="/logout">Logout</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
