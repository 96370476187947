import React, { useState, useRef } from 'react';
import Draggable from 'react-draggable';
import './ChatBotImage.css';
import ChatBot from '../images/chatbot.png';

function ChatBotImage() {
  const widgetContainerRef = useRef(null);
  const [widgetState, setWidgetState] = useState({
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
  });

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = widgetContainerRef?.current?.getBoundingClientRect();
    if (targetRect) {
      setWidgetState((prevState) => ({
        ...prevState,
        bounds: {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        },
      }));
    }
  };

  return (
    <Draggable
      onStart={(event, uiData) => onStart(event, uiData)}
      bounds={widgetState.bounds}
    >
      <div className="chatbot-img" ref={widgetContainerRef}>
        <img
          draggable="false"
          className="chatbot"
          src={ChatBot}
          alt="chatbot-img"
        />
      </div>
    </Draggable>
  );
}

export default ChatBotImage;
