import React, { useState, useRef, useEffect } from 'react';
import Draggable from 'react-draggable';
import SwitchBtn from '../components/SwitchBtn';
import { newUser } from '../utilities/placeholders';
import './Modal.css';

function Modal({
  handleApiKey,
  handleCloseModal,
  closeModal,
  aasbSearch,
  setAasbSearch,
  handleTopic,
}) {
  const [user, setUser] = useState(newUser);
  const [apiKey, setApiKey] = useState('');
  const [edit, setEdit] = useState(false);
  const widgetContainerRef = useRef(null);
  const [widgetState, setWidgetState] = React.useState({
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
  });

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = widgetContainerRef?.current?.getBoundingClientRect();
    if (targetRect) {
      setWidgetState((prevState) => ({
        ...prevState,
        bounds: {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        },
      }));
    }
  };

  useEffect(() => {
    const activeUserFromLocalStrorage = JSON.parse(
      localStorage.getItem('activeUser')
    );
    if (activeUserFromLocalStrorage) {
      setUser({ ...activeUserFromLocalStrorage });
      setApiKey(activeUserFromLocalStrorage.token);
    }
  }, []);

  const handleAasbSearch = () => {
    setAasbSearch((pre) => {
      return !pre;
    });
    setEdit(false);
    setApiKey(user.token);
    handleApiKey(user.token);
  };

  const handleEdit = () => {
    setEdit((pre) => {
      return !pre;
    });
  };

  const handleChange = (event) => {
    setApiKey(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setEdit(false);
    handleApiKey(apiKey);
  };

  return (
    <Draggable
      onStart={(event, uiData) => onStart(event, uiData)}
      bounds={widgetState.bounds}
    >
      <form
        ref={widgetContainerRef}
        style={{ display: closeModal && 'none' }}
        onSubmit={handleSubmit}
        className="modal-comp"
      >
        <div className="header">
          <h5 className="">
            {user.fname ? `Hi, ${user.fname}` : 'AUTH TOKEN'}
          </h5>
          <button
            disabled={!apiKey && true}
            type="button"
            className="btn btn-close"
            onClick={() => {
              handleCloseModal();
            }}
          />
        </div>
        <div className="body">
          <input
            required
            type="password"
            disabled={!edit && true}
            onChange={handleChange}
            value={apiKey}
            style={{ outline: edit && 'solid 1px' }}
            placeholder={
              aasbSearch
                ? 'Please try our search services!'
                : 'Login or enter auth token to unlock chatbot!'
            }
          />
        </div>
        <div className="footer">
          <div className="aasb-toggle-btn">
            <SwitchBtn
              setAasbSearch={handleAasbSearch}
              aasbSearch={aasbSearch}
              handleTopic={handleTopic}
            />
          </div>
          <div className="auth-key-control-btn">
            <button
              disabled={(aasbSearch || user.token) && true}
              onClick={handleEdit}
              type={!edit ? 'submit' : 'button'}
              className="btn btn-success save-change"
            >
              {edit ? 'Save' : 'Edit'}
            </button>
            <button
              disabled={(aasbSearch || user.token) && true}
              onClick={() => {
                setEdit(false);
                setApiKey('');
                handleApiKey('');
              }}
              type="button"
              className="btn btn-dark clear-change"
            >
              Clear
            </button>
          </div>
        </div>
      </form>
    </Draggable>
  );
}

export default Modal;
