import React, { useState, useEffect } from 'react';
import { BiCopyAlt, BiCheckCircle } from 'react-icons/bi';
import axios from '../utilities/httpClient';
import './Form.css';
import ButtonGroup from './ButtonGroup';
import AasbBtnGroup from './AasbBtnGroup';

function Form({
  aasbSearch,
  apiKey,
  topic,
  handleTopic,
  disableScroll,
  addNewSearch,
  clearData,
}) {
  const topics = {
    coc: 'Coc',
    esg: 'Esg',
    acc: 'Acc',
    fin: 'Fin',
    eco: 'Eco',
    sum: 'Sum',
    upload: 'Upload',
  };
  const [load, setLoading] = useState(true);
  const [fileReset, setFileReset] = useState(false);
  const [query, setQery] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [question, setQuestion] = useState('What do you want to know?');
  const [embedFile, setEmbedFile] = useState(null);
  const [copied, setCopied] = useState(false);
  const api_root = process.env.REACT_APP_BACKEND_API_ROOT;
  const api_path = 'chatbot';
  const api_categ = topic;
  const api_endpoint = `${api_root}/${api_path}/${api_categ}`;

  // Browser Tab Close Event
  // const tabReload = async (event) => {
  //   event.preventDefault();
  //   event.returnValue = '';
  // };
  // const tabClose = async () => {
  //   if (!embedFile.collectName) {
  //     return;
  //   }
  //   const api_endpoint = `${api_root}/embeddings/${embedFile.collectName}`;
  //   await axios.post(api_endpoint);
  // };
  // useEffect(() => {
  //   window.addEventListener('beforeunload', tabReload);
  //   window.addEventListener('unload', tabClose);
  //   return () => {
  //     window.removeEventListener('beforeunload', tabReload);
  //     window.removeEventListener('unload', tabClose);
  //   };
  // });

  // Fetch default answer when go to this website
  const fetchDefaultAnswer = async () => {
    try {
      const res = await axios.get(api_endpoint);
      setAnswer(res.data['answer']);
    } catch (err) {
      setAnswer(err.response.data.error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchDefaultAnswer();
  }, []);

  const handleEmbed = (value) => {
    setEmbedFile(value);
  };
  const handleChange = (event) => {
    setQery(event.target.value);
  };

  // Get answers
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!query) {
      return;
    }
    setQuestion(query);
    setAnswer(null);
    setLoading(true);
    try {
      const res = await axios.post(
        api_endpoint,
        {
          query: query,
          collectionName: embedFile?.collectName,
          fileName:
            embedFile?.fileName.split('.')[0].slice(0, 20) + ' ... .pdf:',
        },
        {
          headers: {
            Authorization: apiKey,
          },
        }
      );
      setAnswer(res.data['answer']);
      addNewSearch({
        id: res.data['searchId'],
        question: `@${topics[topic]}${
          topics[topic] === 'Sum'
            ? ' ' +
              embedFile?.fileName.split('.')[0].slice(0, 20) +
              ' ... .pdf:'
            : ''
        } ${query}`,
        answer: res.data['answer'],
      });
    } catch (err) {
      setAnswer(err.response.data.error);
    }
    setQery(null);
    setLoading(false);
  };

  const handleCopy = (value) => {
    setCopied(true);
    navigator.clipboard.writeText(value);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <section
      className={disableScroll ? 'form-comp disable-scroll' : 'form-comp'}
      style={{ zIndex: disableScroll && -1 }}
    >
      {aasbSearch ? (
        <AasbBtnGroup handleTopic={handleTopic} />
      ) : (
        <ButtonGroup
          apiKey={apiKey}
          topic={topic}
          handleTopic={handleTopic}
          handleEmbed={handleEmbed}
          handleLoading={setLoading}
          fileReset={fileReset}
        />
      )}
      <h2 className="greeting-heading">
        {topic === 'upload' && !load && !query
          ? 'Sorry, Errors Happened!'
          : topic === 'sum' && embedFile
          ? 'Questions To This File?'
          : 'What Are You Interested In?'}
      </h2>
      <form className="input-group mb-3" onSubmit={handleSubmit}>
        <input
          type="text"
          disabled={
            (topic === 'upload' ||
              topics[topic] === undefined ||
              load ||
              clearData ||
              !apiKey) &&
            (topic !== 'coc' && topic !== 'esg') & true
          }
          className="form-control"
          onChange={handleChange}
          value={query || ''}
          autoComplete="new-password"
          placeholder={`@${topics[topic]}`}
          required
        />
        <button
          disabled={
            (topic === 'upload' || load || clearData || !apiKey) &&
            (topic !== 'coc' && topic !== 'esg') & true
          }
          className="btn btn-success btn-search"
          type="submit"
        >
          {load ? (
            <div>
              <div className="spinner-grow spinner-grow-sm" role="status" />{' '}
              SEARCHING
            </div>
          ) : (
            'START SEARCH'
          )}
        </button>
      </form>
      <div
        className="file-name-dispaly"
        style={{
          opacity:
            topic !== 'sum' && topic !== 'upload' && !embedFile ? '0' : 1,
        }}
      >
        <p className="upload-file">{`${
          embedFile?.collectName && embedFile?.collectName !== null
            ? 'Current file: '
            : 'Pending: '
        } ${embedFile?.fileName || 'no file uploaded.'}`}</p>
        <button
          className="btn btn-dark"
          onClick={() => {
            setEmbedFile(null);
            setFileReset(true);
            setInterval(() => {
              setFileReset(false);
            }, 500);
          }}
          disabled={embedFile?.collectName !== null && true}
          style={{ opacity: embedFile?.collectName !== null ? 0 : 1 }}
        >
          Cancel
        </button>
      </div>

      <div className="results">
        <div className="results-question">
          <h4>Question</h4>
          <p>{question}</p>
        </div>
        <div className="results-answer">
          <h4>
            Answer
            <span
              className="copy-text"
              onClick={() => handleCopy(`Q: ${question} A: ${answer}`)}
              style={{ marginLeft: '10px' }}
            >
              {copied ? <BiCheckCircle /> : <BiCopyAlt />}
            </span>
          </h4>
          <p>{answer || "Sure! I'll get right on that..."}</p>
        </div>
      </div>
      <div className="retrieve-answer progress">
        <div
          className={
            !load && !clearData
              ? 'progress-bar progress-bar-striped bg-success progress-bar-animated'
              : 'progress-bar progress-bar-striped bg-light progress-bar-animated'
          }
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: '100%' }}
        />
      </div>
    </section>
  );
}

export default Form;
