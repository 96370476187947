export const newUser = {
  email: null,
  fname: null,
  password: null,
  cpassword: null,
  id: null,
  token: null,
  searches: [],
};

export const newSmallMessage = {
  email: null,
  fname: null,
  password: null,
  cpassword: null,
};

export const defaultHistoricalSearches = [
  {
    id: 1,
    question: 'Search History',
    answer: 'Your historical Q&As will be listed here.',
  },
];
