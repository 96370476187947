import React, { useState } from 'react';
import './ButtonGroup.css';

function AasbBtnGroup({ handleTopic }) {
  const [option, setOption] = useState('demo');

  const handleChangeTopic = async (event) => {
    let clickedBtn = event.target.value;
    setOption(clickedBtn);
  };

  const handleSetTopic = (event) => {
    event.preventDefault();
    handleTopic(option);
  };

  return (
    <form
      onSubmit={handleSetTopic}
      className="btn-group-vertical"
      role="group"
      aria-label="Vertical button group"
    >
      <button
        disabled
        type="button"
        className="btn btn-primary"
        style={{ opacity: 1, borderBottom: 'solid 5px #fff' }}
      >
        AASB SEARCH
      </button>
      <button
        onClick={handleChangeTopic}
        type="submit"
        className="btn btn-primary"
        value="esg"
        style={{ opacity: option === 'esg' ? 0.7 : 0.9 }}
      >
        CSR & ESG
      </button>
      <button
        onClick={handleChangeTopic}
        type="submit"
        className="btn btn-primary"
        value="coc"
        style={{ opacity: option === 'coc' ? 0.7 : 0.9 }}
      >
        Cost of Capital
      </button>
    </form>
  );
}

export default AasbBtnGroup;
