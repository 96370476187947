import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import icon from '../images/icon.png';
import './ContactForm.css';

const emailKeys = {
  SERVICE_ID: process.env.REACT_APP_SERVICE_ID,
  TEMPLATE_ID: process.env.REACT_APP_TEMPLATE_ID,
  USER_ID: process.env.REACT_APP_PUBLIC_KEY,
};

function ContactForm({ user }) {
  const [sending, setSending] = useState(false);
  const [greeting, setGreeting] = useState('Get In Touch');
  const [sender, setSender] = useState({
    email: user.email,
    name: user.fname,
    subject: '',
    message: '',
  });

  const handleEmailChange = (event) => {
    setSender((pre) => {
      return { ...pre, email: event.target.value };
    });
  };
  const handleFnameChange = (event) => {
    setSender((pre) => {
      return { ...pre, name: event.target.value };
    });
  };
  const handleSubjectChange = (event) => {
    setSender((pre) => {
      return { ...pre, subject: event.target.value };
    });
  };
  const handleMessageChange = (event) => {
    setSender((pre) => {
      return { ...pre, message: event.target.value };
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSending(true);
    const result = await emailjs.sendForm(
      emailKeys.SERVICE_ID,
      emailKeys.TEMPLATE_ID,
      event.target,
      emailKeys.USER_ID
    );
    setSending(false);
    if (result.text === 'OK') {
      setSender((pre) => {
        return { ...pre, subject: '', message: '' };
      });
      setGreeting('Sent successfully. Thank you!');
      setInterval(() => {
        setGreeting('Got Another Question?');
      }, 3000);
    } else {
      setGreeting('Sorry, please try again!');
    }
  };

  return (
    <div className="contact-form-comp">
      <div className="login-heading">
        <img className="logo-icon" alt="logo-icon" src={icon} />
        <h5>{greeting}</h5>
      </div>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <div className="form-group">
            <label className="form-label mt-4">Email Address</label>
            <input
              onChange={handleEmailChange}
              type="email"
              className="form-control"
              name="email"
              value={sender.email}
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label className="form-label mt-4">First Name</label>
            <input
              onChange={handleFnameChange}
              type="text"
              className="form-control"
              name="name"
              value={sender.name}
              placeholder="Enter name"
            />
          </div>
          <div className="form-group">
            <label className="form-label mt-4">Subject</label>
            <input
              onChange={handleSubjectChange}
              type="text"
              className="form-control"
              name="subject"
              value={sender.subject}
              placeholder="Enter subject"
            />
          </div>
          <div class="form-group">
            <label className="form-label mt-4">Message To Us</label>
            <textarea
              onChange={handleMessageChange}
              class="form-control"
              name="message"
              rows="4"
              value={sender.message}
              placeholder="Enter message"
            ></textarea>
          </div>
          <div class="d-grid">
            <button className="btn btn-lg btn-success" type="submit">
              {sending ? (
                <div>
                  <div className="spinner-grow spinner-grow-sm" role="status" />{' '}
                  <span>Sending Message</span>
                </div>
              ) : (
                'Send Message'
              )}
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default ContactForm;
