import React from 'react';
import RegisterForm from '../components/RegisterForm';
import Copyright from '../components/Copyright';
import { FiKey } from 'react-icons/fi';
import './AuthPage.css';

function AuthPage({ user, setUser }) {
  return (
    <div className="auth-page">
      <RegisterForm user={user} setUser={setUser} />
      <Copyright />
      <FiKey className="key-icon" style={{ opacity: 0.6, cursor: 'default' }} />
    </div>
  );
}

export default AuthPage;
