import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import ChatbotPage from './pages/ChatbotPage';
import AuthPage from './pages/AuthPage';
import ContactPage from './pages/ContactPage';
import { newUser } from './utilities/placeholders';
import 'bootswatch/dist/cyborg/bootstrap.min.css';

function App() {
  const [user, setUser] = useState(newUser);

  /* 💥 Fetch active user from local storage */
  useEffect(() => {
    const activeUserFromLocalStrorage = JSON.parse(
      localStorage.getItem('activeUser')
    );
    if (activeUserFromLocalStrorage) {
      setUser({ ...activeUserFromLocalStrorage });
    }
  }, []);

  const handleLogout = () => {
    setUser({ ...newUser });
    localStorage.removeItem('activeUser');
    localStorage.removeItem('researchQuestions');
    window.location.href = '/';
  };

  return (
    <section>
      <NavBar user={user} handleLogout={handleLogout} />
      <Routes>
        <Route path="/" element={<ChatbotPage user={user} />} />
        <Route path="/contact" element={<ContactPage user={user} />} />
        <Route
          path="/login"
          element={<AuthPage user={user} setUser={setUser} />}
        />
      </Routes>
    </section>
  );
}

export default App;
