import React, { useState } from 'react';
import axios from '../utilities/httpClient';
import './RegisterForm.css';
import icon from '../images/icon.png';
import { newUser, newSmallMessage } from '../utilities/placeholders';

function RegisterForm({ user, setUser }) {
  const [login, setLogin] = useState(true);
  const [load, setLoad] = useState(false);
  const [smallMessage, setSmallMessage] = useState(newSmallMessage);

  const handleEmailChange = (event) => {
    setUser((pre) => {
      return { ...pre, email: event.target.value };
    });
  };
  const handleFnameChange = (event) => {
    setUser((pre) => {
      return { ...pre, fname: event.target.value };
    });
  };
  const handlePasswordChange = (event) => {
    setUser((pre) => {
      return { ...pre, password: event.target.value };
    });
  };
  const handlePasswordConfirm = (event) => {
    setUser((pre) => {
      return { ...pre, cpassword: event.target.value };
    });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoad(true);
    const api_root = process.env.REACT_APP_BACKEND_API_ROOT;
    const api_path = 'auth';
    const api_categ = login ? 'login' : 'register';
    const api_endpoint = `${api_root}/${api_path}/${api_categ}`;
    try {
      const res = await axios.post(api_endpoint, user);
      setUser(() => {
        return { ...newUser, ...res.data };
      });
      localStorage.setItem(
        'activeUser',
        JSON.stringify({ ...newUser, ...res.data })
      );
      window.location.href = '/';
    } catch (err) {
      setUser((pre) => {
        return { ...pre, password: '', cpassword: '' };
      });
      if (err.response.data.email) {
        setSmallMessage(() => {
          return { ...newSmallMessage, email: err.response.data.email };
        });
      } else if (err.response.data.fname) {
        setSmallMessage(() => {
          return { ...newSmallMessage, fname: err.response.data.fname };
        });
      } else if (err.response.data.password) {
        setSmallMessage(() => {
          return { ...newSmallMessage, password: err.response.data.password };
        });
      } else if (err.response.data.cpassword) {
        setSmallMessage(() => {
          return { ...newSmallMessage, cpassword: err.response.data.cpassword };
        });
      }
    }
    setLoad(false);
  };

  return (
    <div className="auth-form-comp">
      <div className="login-heading">
        <img className="logo-icon" alt="logo-icon" src={icon} />
        <h5>{login ? 'Welcome Back!' : 'Welcome To Join!'}</h5>
      </div>
      <form onSubmit={handleFormSubmit}>
        <fieldset>
          <div className="form-group">
            <label className="form-label mt-4">Email Address</label>
            <input
              onChange={handleEmailChange}
              value={user.email}
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter email"
            />
            <small className="form-text text-muted">{smallMessage.email}</small>
          </div>
          <div className="form-group" style={{ display: login && 'none' }}>
            <label className="form-label mt-4">First Name</label>
            <input
              onChange={handleFnameChange}
              value={user.fname}
              type="text"
              className="form-control"
              id="fname"
              placeholder="Enter name"
            />
            <small className="form-text text-muted">{smallMessage.fname}</small>
          </div>
          <div className="form-group">
            <label className="form-label mt-4">Password</label>
            <input
              onChange={handlePasswordChange}
              value={user.password}
              type="password"
              className="form-control"
              id="password"
              placeholder="Enter password"
            />
            <small className="form-text text-muted">
              {smallMessage.password}
            </small>
          </div>
          <div className="form-group" style={{ display: login && 'none' }}>
            <label className="form-label mt-4">Password Confirmation</label>
            <input
              onChange={handlePasswordConfirm}
              value={user.cpassword}
              type="password"
              className="form-control"
              id="confirm-password"
              placeholder="Repeat password"
            />
            <small className="form-text text-muted">
              {smallMessage.cpassword}
            </small>
          </div>
          <div className="submit-group">
            <button type="submit" className="btn btn-success">
              {load ? (
                <div>
                  <div className="spinner-grow spinner-grow-sm" role="status" />{' '}
                  <span>Going</span>
                </div>
              ) : login ? (
                'Login'
              ) : (
                'Register'
              )}
            </button>
            <span onClick={() => setLogin((pre) => !pre)}>
              {login
                ? 'Not registered yet? Register'
                : 'Already registered? Login'}
            </span>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default RegisterForm;
